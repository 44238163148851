module Main exposing (..)

import Browser
import Dict exposing (Dict)
import Html exposing (Html, br, button, div, input, text)
import Html.Attributes exposing (class, classList, value)
import Html.Events exposing (onClick, onInput)
import Set exposing (Set)
import Tuple exposing (first)


main : Program () Model Msg
main =
    Browser.sandbox { init = init, update = update, view = view }


type alias Name =
    String


type alias Id =
    Int


type Event
    = PersonAdded { name : Name }
    | PersonDisabled { id : Id }
    | PersonEnabled { id : Id }
    | ResultRegistered { winner : Id, loser : Id }


type alias MatchIndex =
    Int


type alias PersonSummary =
    { id : Id
    , name : Name
    , wins : Int
    , losses : Int
    , latest : MatchIndex
    , met : Set Id
    , disabled : Bool
    }


newPersonSummary : Id -> String -> PersonSummary
newPersonSummary id name =
    { id = id
    , name = name
    , wins = 0
    , losses = 0
    , latest = 0
    , met = Set.empty
    , disabled = False
    }


addWin : MatchIndex -> Id -> PersonSummary -> PersonSummary
addWin matchIndex against p =
    { p
        | wins = p.wins + 1
        , latest = matchIndex
        , met = Set.insert against p.met
    }


addLoss : MatchIndex -> Id -> PersonSummary -> PersonSummary
addLoss matchIndex against p =
    { p
        | losses = p.losses + 1
        , latest = matchIndex
        , met = Set.insert against p.met
    }


reduceEvents : List Event -> ( Dict Id PersonSummary, Dict Id (Dict Id (Maybe Id)) )
reduceEvents events =
    let
        reducer :
            Event
            -> { idCounter : Int, matchCounter : MatchIndex, dict : Dict Id PersonSummary, grid : Dict Id (Dict Id (Maybe Id)) }
            -> { idCounter : Int, matchCounter : MatchIndex, dict : Dict Id PersonSummary, grid : Dict Id (Dict Id (Maybe Id)) }
        reducer =
            \e acc ->
                case e of
                    PersonAdded { name } ->
                        let
                            id =
                                acc.idCounter
                        in
                        { acc
                            | idCounter = acc.idCounter + 1
                            , dict = Dict.insert id (newPersonSummary id name) acc.dict
                            , grid = Dict.insert id Dict.empty (Dict.map (\_ v -> Dict.insert id Nothing v) acc.grid)
                        }

                    PersonDisabled { id } ->
                        -- TODO remove from grid and all matches
                        -- TODO two different events, one for removing entirely, and one for marking as not active anymore
                        { acc | dict = Dict.update id (Maybe.map (\p -> { p | disabled = True })) acc.dict }

                    PersonEnabled { id } ->
                        { acc | dict = Dict.update id (Maybe.map (\p -> { p | disabled = False })) acc.dict }

                    ResultRegistered { winner, loser } ->
                        let
                            matchIndex =
                                acc.matchCounter + 1

                            updatedWithWinner =
                                case Maybe.map (addWin matchIndex loser) (Dict.get winner acc.dict) of
                                    Nothing ->
                                        acc.dict

                                    Just updatedPerson ->
                                        Dict.insert updatedPerson.id updatedPerson acc.dict

                            updatedWithLoser =
                                case Maybe.map (addLoss matchIndex winner) (Dict.get loser acc.dict) of
                                    Nothing ->
                                        updatedWithWinner

                                    Just updatedPerson ->
                                        Dict.insert updatedPerson.id updatedPerson updatedWithWinner

                            updatedGridWithWinner =
                                Dict.update winner
                                    (\m ->
                                        Maybe.map
                                            (\row ->
                                                if Dict.member loser row then
                                                    Dict.insert loser (Just winner) row

                                                else
                                                    row
                                            )
                                            m
                                    )
                                    acc.grid

                            updatedGridWithLoser =
                                Dict.update loser
                                    (\m ->
                                        Maybe.map
                                            (\row ->
                                                if Dict.member winner row then
                                                    Dict.insert winner (Just winner) row

                                                else
                                                    row
                                            )
                                            m
                                    )
                                    updatedGridWithWinner
                        in
                        { acc
                            | matchCounter = matchIndex
                            , dict = updatedWithLoser
                            , grid = updatedGridWithLoser
                        }

        reduced =
            List.foldl reducer { idCounter = 0, matchCounter = 0, dict = Dict.empty, grid = Dict.empty } events
    in
    ( reduced.dict, reduced.grid )


type alias Model =
    { winnerInput : Maybe Int
    , loserInput : Maybe Int
    , nameInput : String
    , selectedMatch : Maybe ( Id, Id )
    , selectedPerson : Maybe Id
    , events : List Event
    }


init : Model
init =
    { winnerInput = Nothing
    , loserInput = Nothing
    , nameInput = ""
    , selectedMatch = Nothing
    , selectedPerson = Nothing
    , events = []

    -- [ PersonAdded { name = "Stefan" }
    -- , PersonAdded { name = "Johan" }
    -- , PersonAdded { name = "Anton" }
    -- , PersonAdded { name = "Alex" }
    -- , PersonAdded { name = "Hugo" }
    -- , PersonAdded { name = "Natan" }
    -- , PersonAdded { name = "William" }
    -- , PersonAdded { name = "Christoffer" }
    -- , PersonAdded { name = "Niklas" }
    -- , PersonAdded { name = "Niklas" }
    -- , PersonAdded { name = "Vasyl" }
    -- , PersonAdded { name = "Fransman" }
    -- , PersonAdded { name = "Mathias" }
    -- ]
    }


type Msg
    = NameInputChanged String
    | AddPerson
    | DisablePerson Id
    | EnablePerson Id
    | RegisterResult { winner : Id, loser : Id }
    | ResetResults
    | Undo
    | SelectMatch (Maybe ( Id, Id ))
    | SelectPerson (Maybe Id)


update : Msg -> Model -> Model
update msg model =
    case msg of
        NameInputChanged input ->
            { model | nameInput = input }

        AddPerson ->
            case model.nameInput of
                "" ->
                    model

                -- TODO do not add already existing (not removed) name
                name ->
                    { model | nameInput = "", events = model.events ++ [ PersonAdded { name = String.slice 0 32 <| String.concat <| String.words name } ] }

        DisablePerson id ->
            { model | selectedMatch = Nothing, events = model.events ++ [ PersonDisabled { id = id } ] }

        EnablePerson id ->
            { model | selectedMatch = Nothing, events = model.events ++ [ PersonEnabled { id = id } ] }

        RegisterResult result ->
            if result.winner == result.loser then
                model

            else
                { model | events = model.events ++ [ ResultRegistered result ], selectedMatch = Nothing }

        ResetResults ->
            -- TODO remove all inactive persons as well
            { model
                | events =
                    List.filter
                        (\e ->
                            case e of
                                PersonAdded _ ->
                                    True

                                PersonDisabled _ ->
                                    True

                                PersonEnabled _ ->
                                    True

                                ResultRegistered _ ->
                                    False
                        )
                        model.events
            }

        Undo ->
            { model | events = List.take (List.length model.events - 1) model.events }

        SelectMatch match ->
            { model | selectedMatch = match }

        SelectPerson person ->
            { model | selectedPerson = person }


personName : Dict Id PersonSummary -> Id -> String
personName dict id =
    Dict.get id dict |> Maybe.map .name |> Maybe.withDefault "-"


personResult : Dict Id PersonSummary -> Id -> String
personResult dict id =
    Dict.get id dict |> Maybe.map (\p -> String.fromInt p.wins ++ " - " ++ String.fromInt p.losses) |> Maybe.withDefault ""


viewGrid : Dict Id PersonSummary -> Dict Id (Dict Id (Maybe Id)) -> Maybe ( Id, Id ) -> Maybe Id -> List (Html Msg)
viewGrid dict grid selectedMatch selectedPerson =
    let
        gridListAll =
            Dict.toList grid
                |> List.sortBy first
                |> List.map
                    (\( id, list ) ->
                        ( id
                        , Dict.toList list
                            |> List.sortBy (\v -> -(first v))
                        )
                    )

        gridList =
            List.take (List.length gridListAll - 1) gridListAll

        firstRow =
            List.head gridList

        isDisabledPerson id =
            Maybe.withDefault False <| Maybe.map .disabled <| Dict.get id dict

        viewHeader =
            \( _, list ) ->
                div [ class "grid-row" ]
                    (div [ class "corner" ] []
                        :: (list
                                |> List.map
                                    (\( id, _ ) ->
                                        button
                                            [ classList
                                                [ ( "top-header", True )
                                                , ( "button-disabled-person-background", isDisabledPerson id )
                                                , ( "button-selected-person-border", id == gridSelectedPerson )
                                                ]
                                            , onClick <|
                                                SelectPerson
                                                    (if id == gridSelectedPerson then
                                                        Nothing

                                                     else
                                                        Just id
                                                    )
                                            ]
                                            [ div [] [ text (personName dict id) ], div [] [ text (personResult dict id) ] ]
                                    )
                           )
                    )

        maybeHeader =
            Maybe.map viewHeader firstRow

        headerRow =
            Maybe.withDefault (div [] []) maybeHeader

        gridSelectedMatch =
            Maybe.withDefault ( -1, -1 ) selectedMatch

        gridSelectedPerson =
            Maybe.withDefault -1 selectedPerson

        isWinner : Maybe Id -> Id -> Bool
        isWinner result id =
            Maybe.withDefault False <| Maybe.map (\resultId -> resultId == id) result

        isLoser : Maybe Id -> Id -> Bool
        isLoser result id =
            Maybe.withDefault False <| Maybe.map (\resultId -> resultId /= id) result
    in
    headerRow
        :: (gridList
                |> List.map
                    (\( id1, row ) ->
                        div [ class "grid-row" ]
                            (button
                                [ classList
                                    [ ( "left-header", True )
                                    , ( "button-disabled-person-background", isDisabledPerson id1 )
                                    , ( "button-selected-person-border", id1 == gridSelectedPerson )
                                    ]
                                , onClick <|
                                    SelectPerson
                                        (if id1 == gridSelectedPerson then
                                            Nothing

                                         else
                                            Just id1
                                        )
                                ]
                                [ div [] [ text (personName dict id1) ], div [] [ text (personResult dict id1) ] ]
                                :: (row
                                        |> List.map
                                            (\( id2, result ) ->
                                                button
                                                    [ classList
                                                        [ ( "grid-button", True )
                                                        , ( "grid-button-finished", isJust result )
                                                        , ( "button-selected-person-border", id1 == gridSelectedPerson || id2 == gridSelectedPerson )
                                                        , ( "button-disabled-person-background", (isDisabledPerson id1 || isDisabledPerson id2) && isNothing result )
                                                        , ( "button-selected-match-border", ( id1, id2 ) == gridSelectedMatch || ( id2, id1 ) == gridSelectedMatch )
                                                        ]
                                                    , onClick <|
                                                        SelectMatch
                                                            (if isJust result then
                                                                selectedMatch

                                                             else if ( id1, id2 ) == gridSelectedMatch || ( id2, id1 ) == gridSelectedMatch then
                                                                Nothing

                                                             else
                                                                Just ( id1, id2 )
                                                            )
                                                    ]
                                                    [ div
                                                        [ classList
                                                            [ ( "winner", isWinner result id1 )
                                                            , ( "loser", isLoser result id1 )
                                                            ]
                                                        ]
                                                        [ text (personName dict id1) ]
                                                    , text " vs "
                                                    , div
                                                        [ classList
                                                            [ ( "winner", isWinner result id2 )
                                                            , ( "loser", isLoser result id2 )
                                                            ]
                                                        ]
                                                        [ text (personName dict id2) ]
                                                    ]
                                            )
                                   )
                            )
                    )
           )


nextUp : Dict Id PersonSummary -> Maybe ( Id, Id ) -> Maybe ( Id, Id )
nextUp dict extraMatch =
    let
        sortScore p =
            p.latest * 10000000 + p.wins * 1000 + p.id

        sortedWithExtraMatchAdded =
            Dict.values dict
                |> List.filter (\p -> not p.disabled)
                |> List.map
                    (\p ->
                        extraMatch
                            |> Maybe.map
                                (\( id1, id2 ) ->
                                    { p
                                        | latest =
                                            if p.id == id1 || p.id == id2 then
                                                10000

                                            else
                                                p.latest
                                        , met =
                                            if p.id == id1 || p.id == id2 then
                                                Set.insert id2 (Set.insert id1 p.met)

                                            else
                                                p.met
                                    }
                                )
                            |> Maybe.withDefault p
                    )
                |> List.sortBy sortScore

        findFirstUnmet : Id -> PersonSummary -> Maybe Id -> Maybe Id
        findFirstUnmet id p match =
            case match of
                Just _ ->
                    match

                Nothing ->
                    if id == p.id || Set.member id p.met then
                        Nothing

                    else
                        Just p.id

        findFirstMatch : List PersonSummary -> PersonSummary -> Maybe ( Id, Id ) -> Maybe ( Id, Id )
        findFirstMatch allPersons p match =
            case match of
                Just _ ->
                    match

                Nothing ->
                    List.foldl (findFirstUnmet p.id) Nothing allPersons
                        |> Maybe.map (\firstUnmet -> ( p.id, firstUnmet ))

        nextMatch =
            List.foldl (findFirstMatch sortedWithExtraMatchAdded) Nothing sortedWithExtraMatchAdded
    in
    nextMatch


isJust : Maybe a -> Bool
isJust m =
    Maybe.withDefault False <| Maybe.map (\_ -> True) m


isNothing : Maybe a -> Bool
isNothing m =
    Maybe.withDefault True <| Maybe.map (\_ -> False) m


view : Model -> Html Msg
view model =
    let
        ( dict, grid ) =
            reduceEvents model.events

        nextMatch =
            case model.selectedMatch of
                Just m ->
                    Just m

                Nothing ->
                    nextUp dict Nothing

        nextNextMatch =
            nextUp dict nextMatch
    in
    div [ class "main-content" ]
        [ div [] (viewGrid dict grid nextMatch model.selectedPerson)
        , br [] []
        , div [] [ viewCurrentMatch dict nextMatch nextNextMatch (isNothing model.selectedMatch) ]
        , br [] []
        , viewTopList dict
        , br [] []
        , div []
            [ input
                [ value model.nameInput
                , onInput NameInputChanged
                ]
                []
            , button [ onClick AddPerson ] [ text "Add person" ]
            ]
        , br [] []
        , div [] (personsView dict)
        , br [] []
        , div [ class "undo-container" ]
            [ button [ onClick Undo ] [ text "Undo" ]
            , button [ onClick ResetResults ] [ text "Reset matches" ]
            ]
        ]


viewTopList : Dict Id PersonSummary -> Html Msg
viewTopList dict =
    let
        sortedAndIndexed : List ( Int, PersonSummary )
        sortedAndIndexed =
            List.indexedMap Tuple.pair <| List.sortBy (\p -> -p.wins) <| Dict.values dict

        top : List ( Int, PersonSummary )
        top =
            .acc <|
                List.foldl
                    (\( i, p ) prev ->
                        let
                            ii =
                                if p.wins == prev.wins then
                                    prev.i

                                else
                                    i
                        in
                        { i = ii, wins = p.wins, acc = prev.acc ++ [ ( ii, p ) ] }
                    )
                    { i = -1, wins = -1, acc = [] }
                <|
                    sortedAndIndexed
    in
    div []
        (List.map
            (\( i, p ) ->
                div [ class ("top-" ++ String.fromInt (i + 1)) ]
                    [ text (String.fromInt (i + 1) ++ ". " ++ p.name ++ "  (" ++ String.fromInt p.wins ++ " - " ++ String.fromInt p.losses ++ ")")
                    ]
            )
            top
        )


viewCurrentMatch : Dict Id PersonSummary -> Maybe ( Id, Id ) -> Maybe ( Id, Id ) -> Bool -> Html Msg
viewCurrentMatch dict next nextNext isInOrder =
    case next of
        Nothing ->
            div [] []

        Just ( a, b ) ->
            div []
                [ div [ class "current-match" ]
                    [ div []
                        [ button [ class "vs-button", onClick (RegisterResult { winner = a, loser = b }) ]
                            [ text (Dict.get a dict |> Maybe.map .name |> Maybe.withDefault "-") ]
                        , text " vs "
                        , button [ class "vs-button", onClick (RegisterResult { winner = b, loser = a }) ]
                            [ text (Dict.get b dict |> Maybe.map .name |> Maybe.withDefault "-") ]
                        ]
                    ]
                , br [] []
                , case nextNext of
                    Just ( id1, id2 ) ->
                        div [ class "next-match" ]
                            [ div []
                                [ text "Then "
                                , button [ class "vs-button-next", onClick (RegisterResult { winner = id1, loser = id2 }) ]
                                    [ text (Dict.get id1 dict |> Maybe.map .name |> Maybe.withDefault "-") ]
                                , text " vs "
                                , button [ class "vs-button-next", onClick (RegisterResult { winner = id2, loser = id1 }) ]
                                    [ text (Dict.get id2 dict |> Maybe.map .name |> Maybe.withDefault "-") ]
                                ]
                            ]

                    Nothing ->
                        br [] []
                , br [] []
                , button
                    [ classList [ ( "button-selected-match-border", isInOrder ) ]
                    , onClick <| SelectMatch Nothing
                    ]
                    [ text "Next in order" ]
                ]


personsView : Dict Id PersonSummary -> List (Html Msg)
personsView dict =
    Dict.values dict |> List.map personView


personView : PersonSummary -> Html Msg
personView p =
    div []
        [ if p.disabled then
            button [ class "toggle-enable-button", onClick (EnablePerson p.id) ] [ text "Enable " ]

          else
            button [ class "toggle-disable-button", onClick (DisablePerson p.id) ] [ text "Disable" ]
        , text ("  " ++ p.name)
        ]
